import { onUnmounted, onMounted, ref } from 'vue';

export default function useUnsubscribe(collection = null, placeholders = 0, column = null, sort = []) {
    let unsubscribe;
    const isLoading = ref(true);
    const docs = ref(new Array(placeholders));
    const columns = ref(1);
    const forceUnSubscribe = () => unsubscribe();

    onMounted(() => {
        unsubscribe = collection.onSnapshot((querySnapshot) => {
            docs.value = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));

            if (column && sort) {
                let statuses = docs.value.map((doc) => column(doc));
                statuses = sort.filter((c) => statuses.includes(c));

                docs.value = docs.value.map((doc) => ({
                    column: statuses.indexOf(column(doc)) + 1,
                    ...doc,
                }));

                columns.value = [...new Set(statuses)].length;
            }

            /* docs.value = querySnapshot.docChanges().map((snapshot) => ({
                id: snapshot.doc.id,
                change: snapshot.type,
                ...snapshot.doc.data(),
            })); */

            isLoading.value = false;
        }, (err) => {
            console.log(err);
        });
    });

    onUnmounted(() => {
        unsubscribe();
    });

    return { docs, isLoading, columns, forceUnSubscribe };
}
