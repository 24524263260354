<template>
    <Main :center="true" :show-title="!!projects.length">

        <ul v-if="isLoading">
            <Placeholder v-for="index in projects" :key="index" element="li"/>
        </ul>

        <template v-else-if="!projects.length">
            <Empty v-if="!subscription.isActive" illustration="contracts">
                <template #title>Intet abonnement</template>
                <template #description>Tilknyt
                    <span @click="$router.push('/settings')" class="link">abonnement</span>
                    til konto
                </template>
            </Empty>
            <Empty v-else illustration="contracts">
                <template #title>Der er endnu ikke nogen projekter</template>
                <template #description>Opret dit
                    <router-link to="/projects/create">første</router-link>
                    projekt
                </template>
            </Empty>
        </template>

        <ul v-else>
            <li v-for="project in projects" :key="project.id" :style="`background-color: ${project.color};`">
                <input type="checkbox" :id="project.id">
                <label :for="project.id">
                    {{ project.name }}
                    <Icon name="angle"/>
                </label>
                <span>
                    <template v-if="project.id !== selectedProject">
                        <button @click="view(project)">
                            <Icon name="plus"/>
                            Vis
                        </button>
                        <i role="img"></i>
                    </template>
                    <router-link :to="`/projects/${project.id}`">
                        <Icon name="edit"/>
                        Ret
                    </router-link>
                    <template v-if="!('allowDelete' in project) || project.allowDelete">
                        <i role="img"></i>
                        <button @click="remove(project.id)">
                            <Icon name="trash"/>
                            Slet
                        </button>
                    </template>
                </span>
            </li>
        </ul>
    </Main>

    <ActionButton icon="plus" :float="true" @click="create" :disabled="disallowMultipleProjects" :tooltip="buttonTooltip"/>

</template>

<script>
import Main from '@/components/Main.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Icon from '@/components/Icon.vue';
import Empty from '@/components/Empty.vue';
import Placeholder from '@/components/Placeholder.vue';
import useUnsubscribe from '@/utils/useUnsubscribe';
import { convertDate, FieldValue } from '@/utils/firebase';
import { projectsCollection } from '@/utils/collections';
import useState from '@/store';
import { computed } from 'vue';

export default {
    name: 'Projects',
    components: {
        Main,
        ActionButton,
        Icon,
        Empty,
        Placeholder,
    },
    setup() {
        const { setProjectAndCollections, removeProject, project, collections, subscription } = useState();
        const selectedProject = computed(() => project.value.id);
        const placeholders = collections.value?.projects;

        const { docs, isLoading } = useUnsubscribe(projectsCollection().orderBy('viewed', 'desc'), placeholders);
        const canCreateNewProject = computed(() => docs.length >= 1);

        return {
            projects: docs,
            setProjectAndCollections,
            removeProject,
            selectedProject,
            isLoading,
            canCreateNewProject,
            subscription,
        };
    },
    computed: {
        disallowMultipleProjects() {
            const isNotProSubscription = this.projects.length >= 1 && this.subscription.plan !== 'pro';
            return !this.subscription.isActive || isNotProSubscription;
        },
        buttonTooltip() {
            if (!this.disallowMultipleProjects) return null;
            return !this.subscription.isActive ? 'Tilknyt et abonnement til konto, for at oprette projekter' : 'Det er kun muligt at oprette ét projekt med et Basic abonnement';
        },
    },
    methods: {
        create() {
            this.$router.push({ name: 'create-project' });
        },
        view(project) {
            const { id, name, color, address, collections } = project;
            this.setProjectAndCollections({ id, name, color, address }, collections);
            projectsCollection().doc(id).update({ viewed: convertDate(new Date()) });
            this.$router.push('/');
        },
        remove(id) {
            projectsCollection().doc(id).update({ viewed: FieldValue.delete() });
            if (id === this.selectedProject) this.removeProject();
        },
    },
};
</script>

<style scoped lang="less">
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: var(--fieldset-max-width);

    li {
        overflow: hidden;
        border-radius: var(--input-border-radius);
        color: rgb(var(--white-color));
        font-weight: var(--semibold-weight);
        min-height: var(--action-button-height);

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        input[type="checkbox"] {
            display: none;

            &:checked {
                ~ label svg {
                    transform: rotate(0deg);
                }

                ~ span {
                    margin-top: 0;
                }
            }
        }

        label {
            height: var(--action-button-height);
            display: flex;
            position: relative;
            padding: 0 25px;
            box-sizing: border-box;
            font-size: 1.6rem;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            background-color: inherit;

            svg {
                font-size: 0.8em;
                transform: rotate(-90deg);
                transition: transform var(--time-transition) var(--cubic-transition);
            }
        }

        span {
            height: var(--action-button-height);
            margin-top: calc(var(--action-button-height) * -1);
            background: rgba(0, 0, 0, 0.1);
            transition: margin-top var(--time-transition) var(--cubic-transition);
            font-size: 1.4rem;
            display: flex;
            gap: 12px;
            padding: 12px;
            box-sizing: border-box;

            > a, button {
                text-align: center;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                flex: 1;
                transition: background var(--time-transition) var(--cubic-transition);

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                }
            }

            i {
                background: rgba(255, 255, 255, 0.1);
                width: 1px;
            }

            svg {
                margin-right: 10px;
            }
        }
    }
}
</style>
